import React, { Component, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";
//import { FullScreen } from "react-full-screen";
//import windowSize from "react-window-size";
import Navigation from "./Navigation";
import NavBar from "./NavBar";
import Breadcrumb from "./Breadcrumb";
import Configuration from "./Configuration";
import Loader from "../Loader";
import Aux from "../../../hoc/_Aux";
import * as actionTypes from "../../../store/actions";
import accessControl from "../../../menu-items";
import SideBar from "./SideBar";
import moduleNames from "./SideBar/moduleNames";
import { Col, Row } from "react-bootstrap";
import { Footer } from "./Footer/Footer";
import { sidebarModules } from "./NewSidebar/sidebarModules";
 
import NewSideBar from "./NewSidebar/NewSideBar";
import NewNavbar from "./NewNavbar/NewNavbar";

import { Outlet } from 'react-router-dom';
 

class AdminLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    // Add event listener for window resize
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    // Remove event listener to prevent memory leaks
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
 
   UNSAFE_componentWillMount() {
    if (window.innerWidth > 992 && window.innerWidth <= 1024 && this.props.layout !== "horizontal") {
      this.props.onUNSAFE_componentWillMount();
    }
  }
  mobileOutClickHandler() {
    if (window.innerWidth < 992 && this.props.collapseMenu) {
      this.props.onUNSAFE_componentWillMount();
    }
  }


  render() {
    const { width, height } = this.state;
  // Adjust the size of the component based on the window size
  const componentWidth = Math.max(200, width * 0.8);  // At least 200px wide
  const componentHeight = Math.max(150, height * 0.6); // At least 150px tall
    const redirectTo = () => {
      debugger;
      
      const selectedModule = localStorage.getItem("selectedModule");
      const roleId = JSON.parse(localStorage.getItem("roleId"));
      const modules = sidebarModules();
    
      if ((selectedModule === modules[0].name || selectedModule === modules[1].name) && roleId === 1) {
        return "/s-admin/dashboard";
      }
    
      const filteredModules = modules.slice(2).filter(item => !item.hidden);
      const selectedModuleDetails = filteredModules.find(item => item.name === selectedModule);
    
      if (selectedModuleDetails) {
        if (selectedModuleDetails.subMenu) {
          const firstVisibleSubMenu = selectedModuleDetails.subMenu.find(subItem => !subItem.hidden);
          return firstVisibleSubMenu ? firstVisibleSubMenu.url : null;
        }
        return selectedModuleDetails.url;
      }    
      return null;
    };

    const token = localStorage.getItem("token");

    let mainClass = ["pcoded-wrapper"];
    if (this.props.layout === "horizontal" && this.props.subLayout === "horizontal-2") {
      mainClass = [...mainClass, "container"];
    }

    const PageElement = () => {
      return (        
        <div style={{ background: "white" }} className="pcoded-main-container m-0" onClick={() => this.mobileOutClickHandler}>
          <div className={mainClass.join(" ")}>
            <div className="pcoded-content p-0">
              <div className="pcoded-inner-content">
                <div className="main-body">
                  <div className="page-wrapper">  
                   <Outlet  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <Aux>
      
        <div style={{height: height}} className="fullscreen">
        <Row className="m-0">
            <div style={{padding:"0", width:"auto", flexShrink:"unset" }}>
            <NewSideBar props={this.props} />           
            </div>
        
            <Col className="p-0" style={{ overflowX: "clip", width: "50%" }}>            
            <NewNavbar props={this.props}  />
                         <PageElement />
       
              <Footer />
            </Col>
          </Row>
          </div>
         
          {/* <Configuration /> */}
        
      </Aux>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    defaultPath: state.defaultPath,
    isFullScreen: state.isFullScreen,
    collapseMenu: state.collapseMenu,
    layout: state.layout,
    subLayout: state.subLayout,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFullScreenExit: () => dispatch({ type: actionTypes.FULL_SCREEN_EXIT }),
    onUNSAFE_componentWillMount: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
  };
};
//export default connect(mapStateToProps, mapDispatchToProps)(windowSize(AdminLayout));

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
