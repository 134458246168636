import React from "react";
import $ from "jquery";
import accessControl from "./rolePermission";
import moduleNames from "./App/layout/AdminLayout/SideBar/moduleNames";
import { modules } from "./App/layout/AdminLayout/NewSidebar/sidebarModules";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

// #positions
const AHPosition = React.lazy(() => import("./assesshub/position/Position"));
const AHCreatePosition = React.lazy(() => import("./assesshub/position/CreateNewPosition"));
const AHCreateGroup = React.lazy(() => import("./assesshub/group/CreateNewGroup"));
const AHQuestionType = React.lazy(() => import("./assesshub/position/QuestionType"));
const AHInviteCandidates = React.lazy(() => import("./assesshub/position/InviteCandidates"));
const AHReassignCandidates = React.lazy(() => import("./assesshub/position/reassignCandidate"));
const AHRevokeCandidates = React.lazy(() => import("./assesshub/position/revokeCandidate"));
const AHAddUsers = React.lazy(() => import("./assesshub/position/addCandidates"));
const AHPositionUser = React.lazy(() => import("./assesshub/position/positionuser"));
const AHBotapi = React.lazy(() => import("./assesshub/bot_api/login"));
const AHTranslation = React.lazy(() => import("./assesshub/translation/gemini_translation"));
const AHTranslatedQuestions = React.lazy(() => import("./assesshub/translation/translated_questions_listing"));
const AHCreateForm = React.lazy(() => import("./assesshub/custom_forms/createForm"));
const AHCustomForms = React.lazy(() => import("./assesshub/custom_forms/CustomFormsListing"));
const AHCustomFormEdit = React.lazy(() => import("./assesshub/custom_forms/EditForm"));
const AHFlow_id = React.lazy(() => import("./assesshub/bot_api/flow_id"));
const AHKnowledgeBaseFAQ = React.lazy(() => import("./assesshub/knowledgebaseFAQ/faq"));
const AHFormDashboard = React.lazy(() => import("./assesshub/custom_forms/CustomFormDashboard"));
const AHBotDashboard = React.lazy(() => import("./assesshub/bot_api/botDashboard"));
const AHBotUsers = React.lazy(() => import("./assesshub/bot_api/botdetails"));
const AHBotInvite = React.lazy(() => import("./assesshub/bot_api/inviteuser"));
const AHBotInviteHistory = React.lazy(() => import("./assesshub/bot_api/history"));
const AHBotInviteConversation = React.lazy(() => import("./assesshub/bot_api/conversation"));

const AHGroupUser = React.lazy(() => import("./assesshub/group/groupUser"));
const AHuserTest = React.lazy(() => import("./assesshub/group/userTest"));
const AHCandidates = React.lazy(() => import("./assesshub/candidates/Candidates"));
const AHCandidateListing = React.lazy(() => import("./assesshub/candidates/listing"));
const AHCandidatesViewScore = React.lazy(() => import("./assesshub/candidates/ViewScore"));
const AHCandidatesScheduleInteriview = React.lazy(() => import("./assesshub/candidates/ScheduleInterview"));
const AHCandidateDelete = React.lazy(() => import("./assesshub/candidates/deleteCandidate"));
const AHCandidateForceDelete = React.lazy(() => import("./assesshub/candidates/ForceDeleteCandidate"));

const AHInsights = React.lazy(() => import("./assesshub/insights/Insights"));
//test
const AHTest = React.lazy(() => import("./assesshub/test/Test"));
const AHAddQuestion = React.lazy(() => import("./assesshub/test/AddQuestionBySkill"));

const AHAddQuestionGeneric = React.lazy(() => import("./assesshub/test/AddQuestionGenericTest"));
const AHAddQuestionSurvey = React.lazy(() => import("./assesshub/test/AddQuestionSurvey"));
const AHAddQuestionPersonality = React.lazy(() => import("./assesshub/test/AddQuestionPersonality"));
const AHAddQuestionSimulation = React.lazy(() => import("./assesshub/test/AddQuestionSimulation"));
const AHAddQuestionMCOC = React.lazy(() => import("./assesshub/test/AddQuestionMCOC"));
const AHAddQuestionMCMC = React.lazy(() => import("./assesshub/test/AddQuestionMCMC"));
const AHAddQuestion_MCAC_MCNC = React.lazy(() => import("./assesshub/test/AddQuestion-MCAC-MCNC"));
const AHAddQuestionComprehension = React.lazy(() => import("./assesshub/test/AddQuestionComprehension"));
const AHAddQuestionMFITB = React.lazy(() => import("./assesshub/test/AddQuestionMultipleFillInTheBlanks"));
const AHAddQuestionFITB = React.lazy(() => import("./assesshub/test/AddQuestionFillInTheBlanks"));
const AHAddQuestionVideo = React.lazy(() => import("./assesshub/test/AddQuestionVideo"));
const AHAddQuestionFU = React.lazy(() => import("./assesshub/test/AddQuestionFileUpload"));
const AHAddQuestionCL = React.lazy(() => import("./assesshub/test/AddQuestionCodingLanguage"));
const AHAddQuestionEssay = React.lazy(() => import("./assesshub/test/Addquestion-Essay-Speech-Typing"));
const AHAddQuestionMatchTheColumn = React.lazy(() => import("./assesshub/test/AddQuestionMatchTheColumn"));
const AHQuestionTypeScripting = React.lazy(() => import("./assesshub/test/AddQuestionScripting"));
const AHBulkUpload = React.lazy(() => import("./assesshub/test/BulkUpload"));
const AHAddTest = React.lazy(() => import("./assesshub/test/Mytest"));
const AHOnlyAddTest = React.lazy(() => import("./assesshub/test/AddTest"));
const AHAddQuestionMBTI = React.lazy(() => import("./assesshub/test/AddQuestionPersonality-MBTI-PersonalStyle"));

const AHCreateQuestion = React.lazy(() => import("./assesshub/test/CreateNewQuestion"));
const AHQuestionTypeDB = React.lazy(() => import("./assesshub/test/QuestionTypeDB"));
const AHQuestionTypeJava = React.lazy(() => import("./assesshub/test/QuestionTypeJava"));
const AHTestListing = React.lazy(() => import("./assesshub/test/Test"));

const AHProfile = React.lazy(() => import("./assesshub/profile/Profile"));
const AHAddProfile = React.lazy(() => import("./assesshub/profile/AddEditProfile"));
const AHBillInvoice = React.lazy(() => import("./assesshub/profile/BillInvoice"));
const AHBillCredits = React.lazy(() => import("./assesshub/profile/Credits"));
const AHAddUser = React.lazy(() => import("./assesshub/profile/AddEditUser"));
const AHAddLocation = React.lazy(() => import("./assesshub/profile/addlocation"));

const AHMyProfile = React.lazy(() => import("./assesshub/profile/MyProfile"));
const AHMyCompany = React.lazy(() => import("./assesshub/profile/MyCompany"));
const AHTeamManagement = React.lazy(() => import("./assesshub/profile/TeamManagement"));
const AHPlanAndBilling = React.lazy(() => import("./assesshub/profile/PlanAndBilling"));
const AHIntegration = React.lazy(() => import("./assesshub/profile/Integration"));
const AHRegistration = React.lazy(() => import("./assesshub/profile/Registration"));
const AHLocation = React.lazy(() => import("./assesshub/profile/Location"));

const AHGroup = React.lazy(() => import("./assesshub/group/group"));
const AHInstance = React.lazy(() => import("./assesshub/group/instance"));
const AHEditInstance = React.lazy(() => import("./assesshub/group/editInstance"));
const AHEditGroup = React.lazy(() => import("./assesshub/group/editGroup"));

const AHSkillarea = React.lazy(() => import("./assesshub/skillarea/MySkillArea"));
const AHSDefineSkill = React.lazy(() => import("./assesshub/skillarea/DefineSkillArea"));
const AHQuestions = React.lazy(() => import("./assesshub/skillarea/Question"));

// organization
const ORGOrganizations = React.lazy(() => import("./organisation/organisation"));
const ORGEditOrganizations = React.lazy(() => import("./organisation/editOrganization"));
const ORGAddOrganizations = React.lazy(() => import("./organisation/addOrganization"));
const ORGOrganizationBilling = React.lazy(() => import("./organisation/billingAndCollection"));
const ORGDeleteBilling = React.lazy(() => import("./organisation/deleteOrganisation"));
const ORGUpdatePayment = React.lazy(() => import("./organisation/updateOrganisation"));
const ORGrbac = React.lazy(() => import("./organisation/rbac"));
const ORGNewRbac = React.lazy(() => import("./organisation/NewRbac"));
const ORRequestCredit = React.lazy(() => import("./assesshub/request_credit/request_key"));

const RPResumeParser = React.lazy(() => import("./assesshub/ResumeParser/resumeParser"));
const RPViewCandidates = React.lazy(() => import("./assesshub/ResumeParser/viewCandidates"));
const RPConfigure = React.lazy(() => import("./assesshub/ResumeParser/configure"));
const RPProfile = React.lazy(() => import("./assesshub/ResumeParser/rpProfile"));
const notAccess = React.lazy(() => import("./assesshub/position/accessControl"));

const EvaluatorTabs = React.lazy(() => import("./assesshub/evaluator/evaluotorTabs"));
const Evaluator = React.lazy(() => import("./assesshub/evaluator/evaluator"));
const TestAssignment = React.lazy(() => import("./assesshub/evaluator/testAssignment"));
const Evaluate = React.lazy(() => import("./assesshub/evaluator/evaluate"));

// permission:accessControl.
// const permission = {
//     Positions: accessControl['Positions'].moduleAccess,
//     Groups: accessControl['Groups'].moduleAccess,
//     Test: accessControl['Test'].moduleAccess,
//     Candidates: accessControl['Candidates'].moduleAccess,
//     SkillAreas: accessControl['SkillAreas'].moduleAccess,
//     Questions: accessControl['Questions'].moduleAccess,
//     ResumeParser: accessControl['ResumeParser'].moduleAccess,
//     MyProfile: accessControl['MyProfile'].moduleAccess,
//     Insights: accessControl['Insights'].moduleAccess,
// }

// const permission = Object.keys(accessControl).reduce((finalObj, obj) => { return { ...finalObj, [obj]: accessControl[obj].moduleAccess } }, {})

// feedback configure
const AHFBCompetencyMaster = React.lazy(() => import("./assesshub/feedback_configure/CompetencyMaster"));
const AHFBAddCompetencyMaster = React.lazy(() => import("./assesshub/feedback_configure/ConfigureCompetency"));
const AHFBQuestions = React.lazy(() => import("./assesshub/feedback_configure/Questions"));
const AHFBAddQuestions = React.lazy(() => import("./assesshub/feedback_configure/AddQuestion"));
const AHFBBulkQuestions = React.lazy(() => import("./assesshub/feedback_configure/AddQuestionBulkUpload"));
const AHFBSurveys = React.lazy(() => import("./assesshub/feedback_configure/Instances"));
const AHFBAddSurveys = React.lazy(() => import("./assesshub/feedback_configure/CreateInstance"));
const AHFBGroup = React.lazy(() => import("./assesshub/feedback_configure/Group"));
const AHFBAddGroup = React.lazy(() => import("./assesshub/feedback_configure/AddFeedbackGroup"));
const AHFBConfigure = React.lazy(() => import("./assesshub/feedback_configure/MainConfig"));

// feedback assign
const AHFBAssignedSurveys = React.lazy(() => import("./assesshub/feedback_assign/AssignedSurveys"));

// feedback manual assign
const AHFBManualAssign = React.lazy(() => import("./assesshub/feedback_manual_assign/MainAssign"));
const AHFBRegisterUser = React.lazy(() => import("./assesshub/feedback_manual_assign/RegisterUser"));
const AHFBAssignFeedback = React.lazy(() => import("./assesshub/feedback_manual_assign/AssignFeedback"));

// feedback report
const AHFBReport = React.lazy(() => import("./assesshub/feedback_report/Report"));

// ----------------------- Engagement Survey (ES) ----------------------

const AHESCreate = React.lazy(() => import("./assesshub/es_create/Create"));
const AHESConfigure = React.lazy(() => import("./assesshub/es_configure/Configure"));
const AHESReport = React.lazy(() => import("./assesshub/es_report/report"));

// ----------------------- ADC ----------------------------

//ADC CONFIGURE
const AHConfigure = React.lazy(() => import("./assesshub/adc_configure/configure"));
const AHAdcCandidates = React.lazy(() => import("./assesshub/adc_configure/AdcCandidates"));
const AHAddEditAssessment = React.lazy(() => import("./assesshub/adc_configure/addEditAssessment"));

//ADC ASSIGN
const AHAssign = React.lazy(() => import("./assesshub/adc_assign/assign"));
const AHREAssign = React.lazy(() => import("./assesshub/adc_assign/reassign"));
const AHAssignAssessment = React.lazy(() => import("./assesshub/adc_assign/assignAssessment"));
const AHAddEditUser = React.lazy(() => import("./assesshub/adc_assign/addEditUser"));
const AHAddEditEvaluator = React.lazy(() => import("./assesshub/adc_assign/addEditEvaluator"));

//ADC EVALUATE
const AHEvaluate = React.lazy(() => import("./assesshub/adc_evaluate/evaluate"));
const AHEvaluateScore = React.lazy(() => import("./assesshub/adc_evaluate/evaluatescore"));
const AHFinalScore = React.lazy(() => import("./assesshub/adc_evaluate/finalScore"));

const AHAddCompetency = React.lazy(() => import("./assesshub/adc_competency/addCompentency"));
const AHAddTool = React.lazy(() => import("./assesshub/adc_competency/addtool"));
const AHEditCompetency = React.lazy(() => import("./assesshub/adc_competency/editCompetency"));
const AHEditOfflineTool = React.lazy(() => import("./assesshub/adc_competency/editOfflineTool"));

const AHReport = React.lazy(() => import("./assesshub/adc_report/report"));

// batch evaluation
const AHBEProjectInsights = React.lazy(() => import("./assesshub/be_project_insights/ProjectInsights"));
const AHBEProjectInsightsBatchAnalysis = React.lazy(() => import("./assesshub/be_project_insights/BatchAnalysis"));
const AHBEProjectInsightsBatches = React.lazy(() => import("./assesshub/be_batches/Batches"));
const AHBEProjectInsightsCandidates = React.lazy(() => import("./assesshub/be_candidate_analysis/candidates"));
const AHBEProjectInsightsCandidateAnalysisChart = React.lazy(() => import("./assesshub/be_candidate_analysis/CandidateAnalysis"));
const AHBEProjectInsightsStates = React.lazy(() => import("./assesshub/be_state_analysis/state"));
const AHBEProjectInsightsQPAnalysis = React.lazy(() => import("./assesshub/be_project_insights/QPAnalysis"));
const AHBEProjectInsightsSchemes = React.lazy(() => import("./assesshub/be_project_analysis/project"));
const AHBEProjectInsightsTrainingPartners = React.lazy(() => import("./assesshub/be_training_partner/trainingPartner"));
const AHBECreateBatch = React.lazy(() => import("./assesshub/be_batches/createBatches"));
const AHBEAddBatch = React.lazy(() => import("./assesshub/be_batches/addBatch"));
const AHBEReassign = React.lazy(() => import("./assesshub/be_batches/reassignbatchcandidate"));
const AHBERevoke = React.lazy(() => import("./assesshub/be_batches/revokebatchcandidate"));

// s-admin dashboard
const SAdminDashbord = React.lazy(() => import("./assesshub/s_admin_dashboard/Dashboard"));
const SAdminClients = React.lazy(() => import("./assesshub/s_admin_dashboard/Clients"));
const SAdminTypesOfAssessment = React.lazy(() => import("./assesshub/s_admin_dashboard/TypesOfAssessment"));
const SAdminSectors = React.lazy(() => import("./assesshub/s_admin_dashboard/Sectors"));
const SAdminGeographicalPresence = React.lazy(() => import("./assesshub/s_admin_dashboard/GeographicalPresence"));
const SAdminRevenue = React.lazy(() => import("./assesshub/s_admin_dashboard/Revenue"));

// Chatbot
const AHChatBotLogin = React.lazy(() => import("./assesshub/chatbot/ChatbotLogin"));

const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));

// test detail report
const AHDetailReport = React.lazy(() => import("./assesshub/test_reports/DetailReport"));
const AHPersonalizedReport = React.lazy(() => import("./assesshub/test_reports/PersonailzedReport"));
const AHPersonalizedReportAnswerSheet = React.lazy(() => import("./assesshub/test_reports/PersonalizedReportAnswerSheet"));

// Workflow
const AHworkflow = React.lazy(() => import("./assesshub/workflow/Workflow"));
const AHTestStatus = React.lazy(() => import("./assesshub/workflow/TestStatus"));
const AHCreateWorkflow = React.lazy(() => import("./assesshub/workflow/CreateWorkflow"));
const AHEditWorkflow = React.lazy(() => import("./assesshub/workflow/EditWorkflow"));
const AHAssignWorkflow = React.lazy(() => import("./assesshub/workflow/AssignWorkflow"));

// GenAI Interview
const AHGenAIPositions = React.lazy(() => import ('./assesshub/gen_ai_interview/Positions'))
const AHGenAICandidates = React.lazy(() => import ('./assesshub/gen_ai_interview/Candidates'))
const AHCreateEditAIPosition = React.lazy(() => import ('./assesshub/gen_ai_interview/CreatePosition'))
const AHGenAIInviteCandidate = React.lazy(() => import ('./assesshub/gen_ai_interview/InviteCandidate'))

let routes =
  // localStorage.getItem('selectedModule') == moduleNames[0] || localStorage.getItem('selectedModule') == moduleNames[1]
  // || !moduleNames.includes(localStorage.getItem('selectedModule')) ?
  [
    { path: "/s-admin/dashboard", exact: true, hidden: false, permission: true, name: "s_admin_dashboard", component: SAdminDashbord },
    { path: "/s-admin/dashboard/clients", exact: true, hidden: false, permission: true, name: "s_admin_clients", component: SAdminClients },
    {
      path: "/s-admin/dashboard/typesOfAssessment",
      exact: true,
      hidden: false,
      permission: true,
      name: "s_admin_typesOfAssessment",
      component: SAdminTypesOfAssessment,
    },
    { path: "/s-admin/dashboard/sectors", exact: true, hidden: false, permission: true, name: "s_admin_sectors", component: SAdminSectors },
    {
      path: "/s-admin/dashboard/geographical-presence",
      exact: true,
      hidden: false,
      permission: true,
      name: "s_admin_geographical_presence",
      component: SAdminGeographicalPresence,
    },
    { path: "/s-admin/dashboard/revenue", exact: true, hidden: false, permission: true, name: "s_admin_revenue", component: SAdminRevenue },
    // ].filter((i) => i.permission === true) : localStorage.getItem('selectedModule') == moduleNames[1] ? [
    // organization
    {
      path: "/organisation",
      exact: true,
      hidden: JSON.parse(localStorage.getItem("roleId")) != 1,
      permission: JSON.parse(localStorage.getItem("roleId")) == 1,
      name: "Position",
      component: ORGOrganizations,
    },
    {
      path: "/org-billing",
      exact: true,
      hidden: JSON.parse(localStorage.getItem("roleId")) != 1,
      permission: JSON.parse(localStorage.getItem("roleId")) == 1,
      name: "Position",
      component: ORGOrganizationBilling,
    },
    {
      path: "/add-organisation",
      exact: true,
      hidden: JSON.parse(localStorage.getItem("roleId")) != 1,
      permission: JSON.parse(localStorage.getItem("roleId")) == 1,
      name: "Position",
      component: ORGAddOrganizations,
    },
    {
      path: "/edit-organisation/:orgid",
      exact: true,
      hidden: JSON.parse(localStorage.getItem("roleId")) != 1,
      permission: JSON.parse(localStorage.getItem("roleId")) == 1,
      name: "Position",
      component: ORGEditOrganizations,
    },
    {
      path: "/delete-billing",
      exact: true,
      hidden: JSON.parse(localStorage.getItem("roleId")) != 1,
      permission: JSON.parse(localStorage.getItem("roleId")) == 1,
      name: "Position",
      component: ORGDeleteBilling,
    },
    {
      path: "/update-billing",
      exact: true,
      hidden: JSON.parse(localStorage.getItem("roleId")) != 1,
      permission: JSON.parse(localStorage.getItem("roleId")) == 1,
      name: "Position",
      component: ORGUpdatePayment,
    },
    {
      path: "/rbac",
      exact: true,
      hidden: JSON.parse(localStorage.getItem("roleId")) != 1,
      permission: JSON.parse(localStorage.getItem("roleId")) == 1,
      name: "RBAC",
      component: ORGNewRbac,
    },

    // ].filter((i) => i.permission === true) : localStorage.getItem('selectedModule') == moduleNames[2] ? [

    //  Positions,
    {
      path: "/position",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[0].id)?.read,
      name: "Position",
      component: AHPosition,
    },
    {
      path: "/position/create",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[0].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[0].id)?.create,
      name: "CreatePosition",
      component: AHCreatePosition,
    },
    {
      path: "/position/question-type",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[0].id)?.read,
      name: "QuestionType",
      component: AHQuestionType,
    },
    {
      path: "/position/invite-candidates",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission:
        (rolePermission?.find((item) => item.module_id == modules[0].id)?.read &&
          rolePermission?.find((item) => item.module_id == modules[0].id)?.create) ||
        (rolePermission?.find((item) => item.module_id == modules[2].id)?.read &&
          rolePermission?.find((item) => item.module_id == modules[2].id)?.create),
      name: "QuestionType",
      component: AHInviteCandidates,
    },
    {
      path: "/position/reassign-candidates",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[2].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[2].id)?.update,
      name: "QuestionType",
      component: AHReassignCandidates,
    },
    {
      path: "/position/revoke-candidates",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[2].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[2].id)?.update,
      name: "QuestionType",
      component: AHRevokeCandidates,
    },
    {
      path: "/position/invite-candidates/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[0].id)?.read,
      name: "QuestionType",
      component: AHInviteCandidates,
    },
    {
      path: "/position/add-candidates",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[2].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[2].id)?.create,
      name: "QuestionType",
      component: AHAddUsers,
    },
    {
      path: "/position/position-user/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[0].id)?.read,
      name: "PositionUser",
      component: AHPositionUser,
    },

    //  Candidates,
    {
      path: "/candidates",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[2].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[2].id)?.read,
      name: "Candidates",
      component: AHCandidates,
    },
    {
      path: "/candidates/view-score/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[2].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[2].id)?.read,
      name: "viewscore",
      component: AHCandidatesViewScore,
    },
    {
      path: "/candidates/listing/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[2].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[2].id)?.read,
      name: "Listing",
      component: AHCandidateListing,
    },
    {
      path: "/candidates/schedule-interview",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[2].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[2].id)?.read,
      name: "scheduleinterview",
      component: AHCandidatesScheduleInteriview,
    },
    {
      path: "/candidates/delete-candidates",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[2].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[2].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[2].id)?.delete,
      name: "DeleteCandidate",
      component: AHCandidateDelete,
    },
    {
      path: "/candidates/force-delete",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[2].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[2].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[2].id)?.delete,
      name: "ForceDeleteCandidate",
      component: AHCandidateForceDelete,
    },

    //  insights,
    {
      path: "/insights",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[6].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[6].id)?.read,
      name: "Insights",
      component: AHInsights,
    },

    //---------------------------------------- skill-area---------------------------------------------,
    {
      path: "/skill-area/add-question",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestion",
      component: AHAddQuestion,
    },
    {
      path: "/skill-area/add-question/generic",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionGeneric",
      component: AHAddQuestionGeneric,
    },
    {
      path: "/skill-area/add-question/survey",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionSurvey",
      component: AHAddQuestionSurvey,
    },
    {
      path: "/skill-area/add-question/personality",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionPersonality",
      component: AHAddQuestionPersonality,
    },
    {
      path: "/skill-area/add-question/simulation",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionSimulation",
      component: AHAddQuestionSimulation,
    },
    {
      path: "/skill-area/add-question/mcoc",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionMcoc",
      component: AHAddQuestionMCOC,
    },
    {
      path: "/skill-area/add-question/mcoc/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "EditQuestionMcoc",
      component: AHAddQuestionMCOC,
    },
    {
      path: "/skill-area/add-question/mcac/situational-judgement/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "EditQuestionMcacSituationalJudgement",
      component: AHAddQuestion_MCAC_MCNC,
    },
    {
      path: "/skill-area/add-question/mcac/personality-competency/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "EditQuestionMcacPersonalityCompetency",
      component: AHAddQuestion_MCAC_MCNC,
    },
    {
      path: "/skill-area/add-question/mcmc/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "AddQuestionMcmc",
      component: AHAddQuestionMCMC,
    },
    {
      path: "/skill-area/add-question/fitb/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "AddQuestionFitb",
      component: AHAddQuestionFITB,
    },
    {
      path: "/skill-area/add-question/video/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "AddQuestionVideo",
      component: AHAddQuestionVideo,
    },
    {
      path: "/skill-area/add-question/file-upload/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "AddQuestionFU",
      component: AHAddQuestionFU,
    },
    {
      path: "/skill-area/add-question/essay/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "EditQuestionEssay",
      component: AHAddQuestionEssay,
    },
    {
      path: "/skill-area/add-question/speech/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "EditQuestionSpeech",
      component: AHAddQuestionEssay,
    },
    {
      path: "/skill-area/add-question/typing/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "EditQuestionTyping",
      component: AHAddQuestionEssay,
    },
    {
      path: "/skill-area/add-question/mfitb/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "AHAddQuestionMFITB",
      component: AHAddQuestionMFITB,
    },
    {
      path: "/skill-area/add-question/mbti/personality-type-mbti",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AHAddQuestionMBTI",
      component: AHAddQuestionMBTI,
    },
    {
      path: "/skill-area/add-question/mbti/personal-style",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AHAddQuestionMBTIPersonalStyle",
      component: AHAddQuestionMBTI,
    },
    {
      path: "/skill-area/add-question/mbti/personality-type-mbti/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "AHEditQuestionMBTI",
      component: AHAddQuestionMBTI,
    },
    {
      path: "/skill-area/add-question/mbti/personal-style/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "AHEditQuestionMBTIPersonalStyle",
      component: AHAddQuestionMBTI,
    },
    {
      path: "/skill-area/add-question/mcmc",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionMcmc",
      component: AHAddQuestionMCMC,
    },
    {
      path: "/skill-area/add-question/mcac/situational-judgement",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionMcacSituationalJudgement",
      component: AHAddQuestion_MCAC_MCNC,
    },
    {
      path: "/skill-area/add-question/mcac/personality-competency",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionMcacPersonalityCompetency",
      component: AHAddQuestion_MCAC_MCNC,
    },
    {
      path: "/skill-area/add-question/comprehension",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionComprehension",
      component: AHAddQuestionComprehension,
    },
    {
      path: "/skill-area/add-question/mfitb",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AHAddQuestionMFITB",
      component: AHAddQuestionMFITB,
    },
    {
      path: "/skill-area/add-question/fitb",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionFitb",
      component: AHAddQuestionFITB,
    },
    {
      path: "/skill-area/add-question/video",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionVideo",
      component: AHAddQuestionVideo,
    },
    {
      path: "/skill-area/add-question/file-upload",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionFU",
      component: AHAddQuestionFU,
    },
    {
      path: "/skill-area/add-question/coding",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionCL",
      component: AHAddQuestionCL,
    },
    {
      path: "/skill-area/add-question/database",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionDB",
      component: AHQuestionTypeDB,
    },
    {
      path: "/skill-area/add-question/database/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "AddQuestionDB",
      component: AHQuestionTypeDB,
    },
    {
      path: "/skill-area/add-question/java",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionJava",
      component: AHQuestionTypeJava,
    },
    {
      path: "/skill-area/add-question/java/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "AddQuestionJava",
      component: AHQuestionTypeJava,
    },
    {
      path: "/skill-area/add-question/scripting",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionScripting",
      component: AHQuestionTypeScripting,
    },
    {
      path: "/skill-area/add-question/scripting/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "AddQuestionScripting",
      component: AHQuestionTypeScripting,
    },
    {
      path: "/skill-area/add-question/essay",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionEssay",
      component: AHAddQuestionEssay,
    },
    {
      path: "/skill-area/add-question/speech",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionSpeech",
      component: AHAddQuestionEssay,
    },
    {
      path: "/skill-area/add-question/typing",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionTyping",
      component: AHAddQuestionEssay,
    },
    {
      path: "/skill-area/add-question/match-the-column",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionEssay",
      component: AHAddQuestionMatchTheColumn,
    },
    {
      path: "/skill-area/add-question/match-the-column/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.update,
      name: "AddQuestionEssay",
      component: AHAddQuestionMatchTheColumn,
    },
    {
      path: "/skill-area/add-question/bulk-upload",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionEssay",
      component: AHBulkUpload,
    },
    {
      path: "/skill-area/create-question",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.create,
      name: "AddQuestionCQ",
      component: AHCreateQuestion,
    },
    {
      path: "/skill-area",
      exact: true,
      hidden:
        rolePermission?.find((item) => item.module_id == modules[3].id)?.hidden &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[3].id)?.read ||
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read,
      name: "skillarea",
      component: AHSkillarea,
    },
    {
      path: "/skill-area/add",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[3].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[3].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[3].id)?.create,
      name: "skillarea",
      component: AHSDefineSkill,
    },
    {
      path: "/skill-area/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[3].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[3].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[3].id)?.update,
      name: "skillarea",
      component: AHSDefineSkill,
    },
    {
      path: "/skill-area/:tabID",
      exact: true,
      hidden:
        rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden &&
        rolePermission?.find((item) => item.module_id == modules[4].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[3].id)?.read ||
        rolePermission?.find((item) => item.module_id == modules[4].id)?.read,
      name: "skillarea",
      component: AHSkillarea,
    },
    {
      path: "/define-skill",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[3].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[3].id)?.read,
      name: "defineskill",
      component: AHSDefineSkill,
    },

    // -----------------------------------------test-----------------------------------------
    {
      path: "/test",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[1].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[1].id)?.read,
      name: "Test",
      component: AHTest,
    },
    {
      path: "/test/add-test",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[1].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[1].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[1].id)?.create,
      name: "AddTest",
      component: AHAddTest,
    },
    {
      path: "/test/add-personality-test",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[1].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[1].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[1].id)?.create,
      name: "AddPersonalityTest",
      component: AHAddTest,
    },
    {
      path: "/test/edit-test/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[1].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[1].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[1].id)?.update,
      name: "EditTest",
      component: AHAddTest,
    },
    {
      path: "/test/edit-personality-test/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[1].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[1].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[1].id)?.update,
      name: "EditPersonalityTest",
      component: AHAddTest,
    },
    {
      path: "/test/edit-instance/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[1].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[1].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[1].id)?.update,
      name: "Instance",
      component: AHEditInstance,
    },

    // -----------------------------------------profile-----------------------------------------
    { path: "/profile", exact: true, hidden: false, permission: true, name: "Profile", component: AHProfile },
    {
      path: "/profile/billing/add",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[30].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[30].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[30].id)?.create,
      name: "AddProfile",
      component: AHAddProfile,
    },
    {
      path: "/profile/billing/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[30].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[30].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[30].id)?.update,
      name: "EditProfile",
      component: AHAddProfile,
    },
    {
      path: "/profile/billing/invoice",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[30].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[30].id)?.read,
      name: "BillingInvoice",
      component: AHBillInvoice,
    },
    {
      path: "/profile/billing/invoice/credits",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[30].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[30].id)?.read,
      name: "BillingCredits",
      component: AHBillCredits,
    },
    {
      path: "/profile/user/add",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[29].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[29].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[29].id)?.create,
      name: "AddUser",
      component: AHAddUser,
    },
    {
      path: "/profile/user/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[29].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[29].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[29].id)?.update,
      name: "EditUser",
      component: AHAddUser,
    },
    {
      path: "/profile/add-location",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[33].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[33].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[33].id)?.create,
      name: "AddLocation",
      component: AHAddLocation,
    },

    {
      path: "/requestcredit",
      exact: true,
      hidden: JSON.parse(localStorage.getItem("roleId")) != 1 && rolePermission?.find((item) => item.module_id == modules[30].id)?.hidden,
      permission:
        JSON.parse(localStorage.getItem("roleId")) == 1 &&
        rolePermission?.find((item) => item.module_id == modules[30].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[30].id)?.create,
      name: "REQUESTCREDIT",
      component: ORRequestCredit,
    },

    {
      path: "/my-profile",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[27].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[27].id)?.read,
      name: "MyProfile",
      component: AHMyProfile,
    },
    {
      path: "/my-company",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[28].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[28].id)?.read,
      name: "MyCompany",
      component: AHMyCompany,
    },
    {
      path: "/team-management",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[29].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[29].id)?.read,
      name: "TeamManagement",
      component: AHTeamManagement,
    },
    {
      path: "/plan-and-billing",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[30].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[30].id)?.read,
      name: "PlanAndBilling",
      component: AHPlanAndBilling,
    },
    {
      path: "/integration",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[31].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[31].id)?.read,
      name: "Integration",
      component: AHIntegration,
    },
    {
      path: "/registration",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[32].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[32].id)?.read,
      name: "Registration",
      component: AHRegistration,
    },
    {
      path: "/location",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[33].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[33].id)?.read,
      name: "Location",
      component: AHLocation,
    },

    // -----------------------------------------Groups-----------------------------------------
    {
      path: "/group",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[0].id)?.read,
      name: "Group",
      component: AHGroup,
    },
    {
      path: "/group/assign-user/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[0].id)?.read,
      name: "GroupUser",
      component: AHGroupUser,
    },
    {
      path: "/group/user-test",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[0].id)?.read,
      name: "UserTest",
      component: AHuserTest,
    },
    {
      path: "/instance/:id/:pid",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[0].id)?.read,
      name: "Instance",
      component: AHInstance,
    },
    {
      path: "/group/edit-instance/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[0].id)?.read,
      name: "Instance",
      component: AHEditInstance,
    },
    {
      path: "/group/edit-test/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[0].id)?.read,
      name: "EditTest",
      component: AHAddTest,
    },
    {
      path: "/group/create",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[0].id)?.read,
      name: "CreateGroup",
      component: AHCreateGroup,
    },
    {
      path: "/edit-group/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[0].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[0].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[0].id)?.update,
      name: "Group",
      component: AHEditGroup,
    },

    // -----------------------------------------resumeparser-----------------------------------------

    {
      path: "/resume-parser",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[5].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[5].id)?.read,
      name: "resumeparser",
      component: RPResumeParser,
    },
    {
      path: "/view-candidates",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[5].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[5].id)?.read,
      name: "viewCandidates",
      component: RPViewCandidates,
    },
    // { path: '/configure', exact: true, hidden: rolePermission?.find(item => item.module_id == modules[5].id)?.hidden, permission: rolePermission?.find(item => item.module_id == modules[5].id)?.read, name: 'viewCandidates', component: RPConfigure },
    {
      path: "/configure/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[5].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[5].id)?.read,
      name: "viewCandidates",
      component: RPConfigure,
    },
    {
      path: "/create-profile",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[5].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[5].id)?.read,
      name: "rpProifle",
      component: RPProfile,
    },
    {
      path: "/create-profile/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[5].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[5].id)?.read,
      name: "rpProifle",
      component: RPProfile,
    },
    {
      path: "/view-candidates/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[5].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[5].id)?.read,
      name: "viewCandidates",
      component: RPViewCandidates,
    },

    // -----------------------------------------Evaluator---------------------------------------------

    {
      path: "/evaluator",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[16].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[16].id)?.read,
      name: "evaluator",
      component: Evaluator,
    },
    {
      path: "/test-asssigment",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[16].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[16].id)?.read,
      name: "evaluator",
      component: TestAssignment,
    },
    {
      path: "/evaluator",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[16].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[16].id)?.read,
      name: "evaluator",
      component: Evaluator,
    },
    {
      path: "/manual-evaluator",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[16].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[16].id)?.read,
      name: "evaluator",
      component: EvaluatorTabs,
    },
    {
      path: "/evaluate",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[16].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[16].id)?.read,
      name: "evalaute",
      component: Evaluate,
    },

    // ].filter((i) => i.permission === true) : localStorage.getItem('selectedModule') == moduleNames[3] ? [
    // feedback configure
    {
      path: "/fb/configure",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[12].id)?.read,
      name: "configure",
      component: AHFBConfigure,
    },
    {
      path: "/fb/configure/:tabID",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[12].id)?.read,
      name: "configure",
      component: AHFBConfigure,
    },
    //{ path: '/fb/configure/skillarea', exact: true, hidden: rolePermission?.find(item => item.module_id == modules[12].id)?.hidden, permission: rolePermission?.find(item => item.module_id == modules[12].id)?.read, name: 'skillarea', component: AHSkillarea },
    {
      path: "/fb/configure/skillarea/edit",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.update,
      name: "skillarea",
      component: AHSDefineSkill,
    },
    //{ path: '/fb/configure/competencymaster', exact: true, hidden: rolePermission?.find(item => item.module_id == modules[12].id)?.hidden, permission: rolePermission?.find(item => item.module_id == modules[12].id)?.read, name: 'competency', component: AHFBCompetencyMaster },
    {
      path: "/fb/configure/competencymaster/add",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.create,
      name: "competency",
      component: AHFBAddCompetencyMaster,
    },
    {
      path: "/fb/configure/competencymaster/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.update,
      name: "competency",
      component: AHFBAddCompetencyMaster,
    },
    //{ path: '/fb/configure/questions', exact: true, hidden: rolePermission?.find(item => item.module_id == modules[12].id)?.hidden, permission: rolePermission?.find(item => item.module_id == modules[12].id)?.read, name: 'questions', component: AHQuestions },
    {
      path: "/fb/configure/questions/generic",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.create,
      name: "AddQuestionGeneric",
      component: AHAddQuestionGeneric,
    },
    {
      path: "/fb/configure/questions/generic/mcac/add",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.create,
      name: "addquestions",
      component: AHAddQuestion_MCAC_MCNC,
    },
    {
      path: "/fb/configure/questions/generic/mcac/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.update,
      name: "addquestions",
      component: AHAddQuestion_MCAC_MCNC,
    },
    {
      path: "/fb/configure/questions/generic/essay/add",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.create,
      name: "AddQuestionEssay",
      component: AHAddQuestionEssay,
    },
    {
      path: "/fb/configure/questions/generic/essay/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.update,
      name: "EditQuestionEssay",
      component: AHAddQuestionEssay,
    },
    {
      path: "/fb/configure/questions/bulkupload",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.create,
      name: "addbulkquestions",
      component: AHBulkUpload,
    },
    //{ path: '/fb/configure/surveys', exact: true, hidden: rolePermission?.find(item => item.module_id == modules[12].id)?.hidden, permission: rolePermission?.find(item => item.module_id == modules[12].id)?.read, name: 'surveys', component: AHFBSurveys },
    {
      path: "/fb/configure/surveys/add",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.create,
      name: "addsurveys",
      component: AHOnlyAddTest,
    },
    {
      path: "/fb/configure/surveys/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.update,
      name: "addsurveys",
      component: AHOnlyAddTest,
    },
    //{ path: '/fb/configure/group', exact: true, hidden: rolePermission?.find(item => item.module_id == modules[12].id)?.hidden, permission: rolePermission?.find(item => item.module_id == modules[12].id)?.read, name: 'group', component: AHFBGroup },
    {
      path: "/fb/configure/group/add",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.create,
      name: "addgroup",
      component: AHFBAddGroup,
    },
    {
      path: "/fb/configure/group/edit/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[12].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[12].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[12].id)?.update,
      name: "editgroup",
      component: AHFBAddGroup,
    },

    // feedback assign
    {
      path: "/fb/assign",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[13].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[13].id)?.read,
      name: "assign",
      component: AHFBAssignedSurveys,
    },

    // feedback manual assign
    {
      path: "/fb/assign/manual-assign",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[13].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[13].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[13].id)?.create,
      name: "manual-assign",
      component: AHFBManualAssign,
    },
    {
      path: "/fb/assign/manual-assign/user/add",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[13].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[13].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[13].id)?.create,
      name: "registeruser",
      component: AHFBRegisterUser,
    },
    {
      path: "/fb/assign/manual-assign/feedback/assign",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[13].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[13].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[13].id)?.create,
      name: "assignfeedback",
      component: AHFBAssignFeedback,
    },
    {
      path: "/fb/force-delete",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[13].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[13].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[13].id)?.delete,
      name: "ForceDeleteCandidate",
      component: AHCandidateForceDelete,
    },

    // feedback report
    {
      path: "/fb/report",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[14].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[14].id)?.read,
      name: "report",
      component: AHFBReport,
    },
    // ].filter((i) => i.permission === true) : localStorage.getItem('selectedModule') == moduleNames[4] ? [
    // ES Configure
    {
      path: "/es/configure/add-survey",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.create,
      name: "es_addsurvey",
      component: AHOnlyAddTest,
    },
    {
      path: "/es/configure",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[17].id)?.read,
      name: "es_configure",
      component: AHESCreate,
    },
    {
      path: "/es/configure/:tabID",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[17].id)?.read,
      name: "es_configure",
      component: AHESCreate,
    },
    {
      path: "/es/skill-area/edit",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.update,
      name: "skillarea",
      component: AHSDefineSkill,
    },
    {
      path: "/es/configure/add-question/generic",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.create,
      name: "skillarea",
      component: AHAddQuestionGeneric,
    },
    {
      path: "/es/configure/add-question/generic/mcnc",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.create,
      name: "skillarea",
      component: AHAddQuestion_MCAC_MCNC,
    },
    {
      path: "/es/configure/edit-question/generic/mcnc/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.update,
      name: "es_editquestion",
      component: AHAddQuestion_MCAC_MCNC,
    },
    {
      path: "/es/configure/add-question/generic/essay",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.create,
      name: "AddQuestionEssay",
      component: AHAddQuestionEssay,
    },
    {
      path: "/es/configure/edit-question/generic/essay/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.update,
      name: "EditQuestionEssay",
      component: AHAddQuestionEssay,
    },
    {
      path: "/es/configure/questions/bulkupload",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.create,
      name: "es_addbulkquestions",
      component: AHBulkUpload,
    },
    {
      path: "/es/instance/:id/:pid",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[17].id)?.read,
      name: "es_instance",
      component: AHInstance,
    },
    {
      path: "/es/group/edit-survey-parameters/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[17].id)?.read,
      name: "Instance",
      component: AHEditInstance,
    },
    {
      path: "/es/group/edit-survey/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[17].id)?.read,
      name: "EditTest",
      component: AHOnlyAddTest,
    },
    {
      path: "/es/position-user/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[17].id)?.read,
      name: "es_positionuser",
      component: AHPositionUser,
    },
    {
      path: "/es/edit-group/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.update,
      name: "es_edit",
      component: AHEditGroup,
    },
    {
      path: "/es/configure/add-survey",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.create,
      name: "es_addsurvey",
      component: AHOnlyAddTest,
    },
    {
      path: "/es/configure/edit-survey/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.update,
      name: "es_editsurvey",
      component: AHOnlyAddTest,
    },
    {
      path: "/es/configure/configure-survey-parameters/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[17].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[17].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[17].id)?.update,
      name: "es_configure_survey_parameters",
      component: AHEditInstance,
    },

    // ES Assign
    {
      path: "/es/assign",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[18].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[18].id)?.read,
      name: "es_assign",
      component: AHCandidates,
    },
    {
      path: "/es/createGroup",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[18].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[18].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[18].id)?.create,
      name: "es_assign",
      component: AHCreatePosition,
    },
    {
      path: "/es/invite",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[18].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[18].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[18].id)?.create,
      name: "es_assign",
      component: AHInviteCandidates,
    },
    {
      path: "/es/add-user",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[18].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[18].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[18].id)?.create,
      name: "es_assign",
      component: AHAddUsers,
    },
    {
      path: "/es/delete-user",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[18].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[18].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[18].id)?.delete,
      name: "es_assign",
      component: AHCandidateDelete,
    },
    {
      path: "/es/force-delete",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[18].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[18].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[18].id)?.delete,
      name: "es_assign",
      component: AHCandidateForceDelete,
    },
    {
      path: "/es/re-assign",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[18].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[18].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[18].id)?.update,
      name: "es_assign",
      component: AHReassignCandidates,
    },
    {
      path: "/es/revoke",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[18].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[18].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[18].id)?.update,
      name: "es_revoke",
      component: AHRevokeCandidates,
    },

    // ES Reports
    {
      path: "/es/report",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[19].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[19].id)?.read,
      name: "es_report",
      component: AHESReport,
    },
    // ].filter((i) => i.permission === true) : localStorage.getItem('selectedModule') == moduleNames[5] ? [
    //ADC CONFIGURE
    {
      path: "/adc/configure",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[8].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[8].id)?.read,
      name: "configure",
      component: AHConfigure,
    },
    {
      path: "/adc/configure/candidates/:id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[8].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[8].id)?.read,
      name: "adc",
      component: AHAdcCandidates,
    },
    {
      path: "/adc/addAssessment",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[8].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[8].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[8].id)?.create,
      name: "AddAsessment",
      component: AHAddEditAssessment,
    },
    {
      path: "/adc/editAssessment",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[8].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[8].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[8].id)?.update,
      name: "EditAssessment",
      component: AHAddEditAssessment,
    },

    //ADC ASSIGN
    {
      path: "/adc/assign",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[9].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[9].id)?.read,
      name: "Assign",
      component: AHAssign,
    },
    {
      path: "/adc/assign/:tabID",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[9].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[9].id)?.read,
      name: "Assign",
      component: AHAssign,
    },
    {
      path: "/adc/reassign",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[9].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[9].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[9].id)?.create,
      name: "Reassign",
      component: AHREAssign,
    },
    {
      path: "/adc/assignAssessment",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[9].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[9].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[9].id)?.create,
      name: "AssignAssessment",
      component: AHAssignAssessment,
    },
    {
      path: "/adc/addUser",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[9].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[9].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[9].id)?.create,
      name: "AddUser",
      component: AHAddEditUser,
    },
    {
      path: "/adc/editUser/:user_id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[9].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[9].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[9].id)?.update,
      name: "EditUser",
      component: AHAddEditUser,
    },
    {
      path: "/adc/addEvaluator",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[9].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[9].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[9].id)?.create,
      name: "AddEvaluator",
      component: AHAddEditEvaluator,
    },
    {
      path: "/adc/editEvaluator/:id/:user_id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[9].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[9].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[9].id)?.update,
      name: "EditEvalautor",
      component: AHAddEditEvaluator,
    },
    {
      path: "/adc/force-delete",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[9].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[9].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[9].id)?.delete,
      name: "ForceDeleteCandidate",
      component: AHCandidateForceDelete,
    },

    //ADC EVALUATOR
    {
      path: "/adc/evaluate",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[10].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[10].id)?.read,
      name: "evaluate",
      component: AHEvaluate,
    },
    {
      path: "/adc/evaluate/:tabID",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[10].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[10].id)?.read,
      name: "evaluate",
      component: AHEvaluate,
    },
    {
      path: "/adc/evaluatescore",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[10].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[10].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[10].id)?.create,
      name: "EvaluteScore",
      component: AHEvaluateScore,
    },
    {
      path: "/adc/finalScore",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[10].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[10].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[10].id)?.update,
      name: "FinalScore",
      component: AHFinalScore,
    },

    //ADC REPORTS
    {
      path: "/adc/report",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[11].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[11].id)?.read,
      name: "Report",
      component: AHReport,
    },
    // ].filter((i) => i.permission === true) : localStorage.getItem('selectedModule') == moduleNames[6] ? [
    // BE Dashboard
    {
      path: "/be/project-insights",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[15].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[15].id)?.read,
      name: "be_project_insights",
      component: AHBEProjectInsights,
    },
    {
      path: "/be/project-insights/batches",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[15].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[15].id)?.read,
      name: "be_project_insights_batches",
      component: AHBEProjectInsightsBatches,
    },
    {
      path: "/be/project-insights/batch-analysis",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[15].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[15].id)?.read,
      name: "be_project_insights_batch_analysis",
      component: AHBEProjectInsightsBatchAnalysis,
    },
    {
      path: "/be/project-insights/candidates",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[15].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[15].id)?.read,
      name: "be_batches",
      component: AHBEProjectInsightsCandidates,
    },
    {
      path: "/be/project-insights/candidates/candidate-analysis",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[15].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[15].id)?.read,
      name: "be_project_insights_candidate_analysis",
      component: AHBEProjectInsightsCandidateAnalysisChart,
    },
    {
      path: "/be/project-insights/states",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[15].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[15].id)?.read,
      name: "be_project_insights_states",
      component: AHBEProjectInsightsStates,
    },
    {
      path: "/be/project-insights/qp-analysis",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[15].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[15].id)?.read,
      name: "be_project_insights_qp_analysis",
      component: AHBEProjectInsightsQPAnalysis,
    },
    {
      path: "/be/project-insights/schemes",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[15].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[15].id)?.read,
      name: "be_project_insights_schemes",
      component: AHBEProjectInsightsSchemes,
    },
    {
      path: "/be/project-insights/training-partners",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[15].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[15].id)?.read,
      name: "be_project_insights_training_partners",
      component: AHBEProjectInsightsTrainingPartners,
    },

    // BE Create
    {
      path: "/be/create-batch",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[26].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[26].id)?.read,
      name: "be_create_batch",
      component: AHBECreateBatch,
    },
    {
      path: "/be/add-batch",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[26].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[26].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[26].id)?.create,
      name: "be_add_batch",
      component: AHBEAddBatch,
    },
    {
      path: "/be/re-assign",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[26].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[26].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[26].id)?.update,
      name: "be_re_assign",
      component: AHBEReassign,
    },
    {
      path: "/be/revoke",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[26].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[26].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[26].id)?.update,
      name: "be_revoke",
      component: AHBERevoke,
    },
    // ].filter((i) => i.permission === true) : localStorage.getItem('selectedModule') == moduleNames[7] ? [
    {
      path: "/bot-api",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[34].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[34].id)?.read,
      name: "bot_api",
      component: AHChatBotLogin,
    },
    {
      path: "/bot-api/flow_id",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[34].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[34].id)?.read,
      name: "flow_id",
      component: AHFlow_id,
    },
    {
      path: "/bot-api/flow_id/dashboard/:botId/",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[34].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[34].id)?.read,
      name: "dashboard",
      component: AHBotDashboard,
    },
    {
      path: "/bot-api/flow_id/dashboard/:botId/:tabId",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[34].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[34].id)?.read,
      name: "dashboard",
      component: AHBotDashboard,
    },
    {
      path: "/bot-api/flow_id/dashboard/:botId/users",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[34].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[34].id)?.read,
      name: "users",
      component: AHBotUsers,
    },
    {
      path: "/bot-api/flow_id/dashboard/:botId/invite",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[34].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[34].id)?.read,
      name: "invite",
      component: AHBotInvite,
    },
    {
      path: "/bot-api/flow_id/dashboard/:botId/history",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[34].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[34].id)?.read,
      name: "invitehistory",
      component: AHBotInviteHistory,
    },
    {
      path: "/bot-api/flow_id/dashboard/:botId/conversation",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[34].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[34].id)?.read,
      name: "inviteconversation",
      component: AHBotInviteConversation,
    },
    // ].filter((i) => i.permission === true) : localStorage.getItem('selectedModule') == moduleNames[8] ? [

    // Translation
    {
      path: "/translate",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[35].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[35].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[35].id)?.create,
      name: "translation",
      component: AHTranslation,
    },
    { path: "/translatedquestions", exact: true, permission: true, name: "translatedquestions", component: AHTranslatedQuestions },
    // FAQ
    {
      path: "/faq",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[36].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[36].id)?.read,
      name: "faq",
      component: AHKnowledgeBaseFAQ,
    },

    // Custom Forms
    {
      path: "/create-form",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[37].id)?.hidden,
      permission:
        rolePermission?.find((item) => item.module_id == modules[37].id)?.read &&
        rolePermission?.find((item) => item.module_id == modules[37].id)?.create,
      name: "createcustomform",
      component: AHCreateForm,
    },
    {
      path: "/created-forms",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[37].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[37].id)?.read,
      name: "customformsListing",
      component: AHCustomForms,
    },
    {
      path: "/dashboard/:formName",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[37].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[37].id)?.read,
      name: "customformview",
      component: AHFormDashboard,
    },
    { path: "/editcustomform/:orgid/:formName", exact: true, permission: true, name: "customformedit", component: AHCustomFormEdit },

    {
      path: "/ahdetailrpt",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[1].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[1].id)?.read,
      name: "DetailReport",
      component: AHDetailReport,
    },
    {
      path: "/ahpersonalizedrpt",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[1].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[1].id)?.read,
      name: "PersonalizedReport",
      component: AHPersonalizedReport,
    },
    {
      path: "/ahpersonalizedrptas",
      exact: true,
      hidden: rolePermission?.find((item) => item.module_id == modules[1].id)?.hidden,
      permission: rolePermission?.find((item) => item.module_id == modules[1].id)?.read,
      name: "PersonalizedReportAnswerSheet",
      component: AHPersonalizedReportAnswerSheet,
    },

    // Workflow
    { path: "/workflow", exact: true, permission: true, name: "workflow", component: AHworkflow },
    { path: "/workflow/create", exact: true, permission: true, name: "createworkflow", component: AHCreateWorkflow },
    { path: "/workflow/edit/:id", exact: true, permission: true, name: "editworkflow", component: AHEditWorkflow },
    { path: "/workflow/assign", exact: true, permission: true, name: "assignworkflow", component: AHAssignWorkflow },

    // Workflow Test Status
    { path: "/workflow-assignment", exact: true, permission: true, name: "teststatus", component: AHTestStatus },

    // GenAI Video Interview
    { path: '/gen-ai/positions', exact: true, permission: true, name: 'Positions', component: AHGenAIPositions },
    { path: '/gen-ai/candidates', exact: true, permission: true, name: 'Candidates', component: AHGenAICandidates },
    { path: '/gen-ai/position/add', exact: true, permission: true, name: 'Add Position', component: AHCreateEditAIPosition },
    { path: '/gen-ai/candidates/invite', exact: true, permission: true, name: 'Invite Candidate', component: AHGenAIInviteCandidate },
  ].filter((i) => i.hidden != true);
// : []

if (routes.length == 0) {
  routes = [
    // -----------------------------------------notAccess-----------------------------------------
    { path: "/not-access", exact: true, hidden: false, permission: true, name: "notAccess", component: notAccess },
  ];
} else {
  routes.forEach((i) => {
    if (i.permission != true) {
      i.component = notAccess;
    }
  });
}

export default routes;
